function SecWelcomeContent({ img }) {
    return (
        <div className="welcome-content">
            <div className="promo-section">
                <div className="integration-link">
                    <span className="integration-icon">
                        <img src={img} width="24" height="24" alt="" />
                    </span>
                    <span className="integration-text">DeFi and AI Solutions</span>
                </div>
            </div>
            <h1>Artificial Intelligence Crypto Revolution</h1>
            <p>
                StonFi AI offers innovative DeFi and AI solutions, driving the next revolution in artificial intelligence and cryptocurrency. Unlocking advanced crypto opportunities through AI-powered strategies.
            </p>
            <div className="group-btn-welcome">
                <div className="dream-btn-group">
                    <a href="https://stonfi-ai.gitbook.io/whitepaper-docs-stonfi.ai/" className="btn dream-btn mr-3">Whitepaper</a>
                    <a href="https://t.me/tonfigroupchat" className="btn dream-btn">Contact Us</a>
                </div>

                <div className="dream-btn-group">
                    <a href="https://app.stonfi.ai/swap" className="btn dream-btn mr-3">Swap Now</a>
                    <a href="https://app.stonfi.ai/staking" className="btn dream-btn mr-3">Stake Now</a>
                </div>
            </div>
        </div>
    )
}

export default SecWelcomeContent