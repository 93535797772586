import SectionHeading from '../../../components/SectionHeading'
import Timeline from './Timeline'

const SecOurRoadmap = ({ data, img }) => {

  return (
    <section className="roadmap section-padding-100" style={{ background: `url(${img}) center center / cover no-repeat`, backgroundSize: 'cover' }} id="chart">
      <SectionHeading
        title='TONFI/USDT Chart'
        text=''
      />
      <div className="container-fluid">
        <div className="row">
            <div id="section_5-slider-circle"  className="container-fluid" style={{
              height: '70vh',
              padding: '0 5%'
            }}>
              <iframe height="100%" width="100%" id="geckoterminal-embed" title="GeckoTerminal Embed" src="https://www.geckoterminal.com/ton/pools/EQA2-p46rF9kGNrXHLwS9Dq7pcSqfVnVjr_gWR34x0UTa1bV?embed=1&info=0&swaps=0&grayscale=1&light_chart=0&chart_type=price&resolution=15m" frameborder="0" allow="clipboard-write" allowfullscreen></iframe>
            </div>
          </div>
      </div>
    </section>
  );
}

export default SecOurRoadmap;